// extracted by mini-css-extract-plugin
export var ArtistDescription = "JoseParla-module--ArtistDescription--yBdm8";
export var ArtistInfos = "JoseParla-module--ArtistInfos--3qJks";
export var ButtonWrapper = "JoseParla-module--ButtonWrapper --qcikA";
export var CardWrapper = "JoseParla-module--CardWrapper--B2qIu";
export var CarrouselWrapper2 = "JoseParla-module--CarrouselWrapper2--EekyS";
export var Citations = "JoseParla-module--Citations--yy9H1";
export var DescriptionWrapper = "JoseParla-module--DescriptionWrapper--zbDQq";
export var ImageWrapper = "JoseParla-module--ImageWrapper--AaLqV";
export var LinkWrapper = "JoseParla-module--LinkWrapper--Rcck4";
export var MobileProtrait = "JoseParla-module--MobileProtrait--Z6pDt";
export var More = "JoseParla-module--More--Wq2cb";
export var MoreButton = "JoseParla-module--MoreButton--s+6sZ";
export var NameWrapper = "JoseParla-module--NameWrapper--yHFLd";
export var PdpWrapper = "JoseParla-module--PdpWrapper--NZnbj";
export var PhotosWrapper = "JoseParla-module--PhotosWrapper--dS7kn";
export var ProfilWrapper = "JoseParla-module--ProfilWrapper--oloTT";
export var TitleWrapper = "JoseParla-module--TitleWrapper--9Sdfr";
export var Wrapper = "JoseParla-module--Wrapper--x5nkZ";